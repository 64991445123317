<template>
  <div class="comment-item" v-bind:class="{ 'comment-item_child': child }">
    <div class="comment-item__name">
      {{ comment.firstName }} <span class="comment-item__date">{{ comment.createdAt }}</span>
    </div>
    <div class="comment-item__text">
      {{ comment.message }}
    </div>
    <div class="comment-item__controls">
      <span class="comment-item__reply" v-if="!child" v-on:click="isReply = true">Ответить</span>
      <span v-if="canDelete || userId === comment.userId" class="comment-item__delete" v-on:click="deleteComment">Удалить</span>
    </div>
    <comment-form @create="answer"
                  :entity="entity"
                  :entity-id="entityId"
                  :reply-id="comment.id"
                  v-if="isReply">
    </comment-form>
  </div>
</template>

<script>
import CommentForm from "../components/CommentForm";

export default {
  components: {
    CommentForm
  },
  props: ['comment', 'child', 'entity', 'entityId', 'canDelete', 'userId'],
  computed: {},
  data: () => {
    return {
      isReply: false
    }
  },
  methods: {
    answer(data) {
      this.comment.parents.push(data)
      this.isReply = false;
    },
    deleteComment() {
      this.$emit('deleteComment', {
        id: this.comment.id
      });
    }
  },
}
</script>

<style scoped lang="scss">
@import "src/scss/mixins";

.comment-item {
  margin-bottom: 10px;
  position: relative;
  border: 2px solid #b9e4ff;
  border-radius: 4px;
  padding: 10px;
  background: #fff;

  &__name {
    font-weight: bold;
  }

  &_child {
    margin-left: 20px;
  }

  &__text {
    margin-bottom: 10px;
    white-space: pre-line;
  }

  &__controls {
    position: absolute;
    right: 10px;
    top: 10px;
  }

  &__delete,
  &__reply {
    cursor: pointer;
  }

  &__reply {
    margin-right: 10px;
  }

  &__date {
    font-weight: normal;
    color: #00c689;
    font-size: 11px;
    margin-left: 10px;
  }
}
</style>
