import Vue from 'vue'
import VueMask from 'v-mask'

// Vue.config.productionTip = false;
// Vue.prototype.$apiEndpoint = '';
Vue.use(VueMask);

Vue.component('comments', require('./components/comments/Commets.vue').default);
Vue.component('check-email', require('./components/check-email/CheckEmail.vue').default);
Vue.component('notifications', require('./components/notifications/Notifications.vue').default);


new Vue({
  el: '#app',
});
