<template>
  <div>
    <textarea v-model="message" class="form-control" cols="30" rows="3"></textarea>
    <button v-on:click="submit" class="btn btn-success btn-sm">ответить</button>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: 'CommentForm',
  components: {},
  props: ['replyId', 'entity', 'entityId'],
  computed: {},
  data: () => {
    return {
      message: null
    }
  },
  methods: {
    submit() {
      axios.post('/comments/create', {
        message: this.message,
        entity: this.entity,
        entityId: this.entityId,
        rootId: this.replyId
      })
        .then((response) => {
          this.$emit('create', response.data);
        })
        .catch((error) => {
          console.log(error.response.data)
        });

      this.message = null;
    }
  },
}
</script>

<style scoped lang="scss">
@import "src/scss/mixins";

.form-control {
  margin-bottom: 10px;
}
</style>
