<template>
  <div class="comments">
    <div v-for="comment in comments" v-bind:key="comment.id">
      <comment-item
        :entity="entity"
        :entity-id="entityId"
        :comment="comment"
        :user-id="userId"
        :can-delete="canDelete"
        @deleteComment="deleteComment">
      </comment-item>
      <comment-item
        @deleteComment="deleteComment"
        v-for="item in comment.parents"
        :user-id="userId"
        :can-delete="canDelete"
        :comment="item"
        :child="true"
        v-bind:key="item.id">
      </comment-item>
    </div>

    <comment-form :entity="entity" :entity-id="entityId" @create="create"></comment-form>
  </div>
</template>

<script>
import CommentItem from "./components/CommentItem";
import CommentForm from "./components/CommentForm";
import axios from "axios";

export default {
  name: 'Comments',
  components: {
    CommentItem,
    CommentForm
  },
  props: ['userName', 'canDelete', 'userId', 'entity', 'entityId'],
  data: () => {
    return {
      comments: []
    }
  },
  methods: {
    deleteComment(data) {
      if (confirm('Точно удалить?')) {
        this.comments.forEach((comment, index) => {
          if (data.id === comment.id) {
            this.comments.splice(index, 1);
          }

          comment.parents.forEach((parent, i) => {
            if (data.id === parent.id) {
              comment.parents.splice(i, 1);
            }
          })
        })

        axios.delete(`/comments/delete?id=${data.id}`)
          .then(() => {
          })
          .catch((error) => {
            console.log(error.response.data)
          });
      }
    },
    create(data) {
      this.comments.push(data)
    }
  },
  mounted() {
    axios.get(`/comments/index?entity=${this.entity}&entityId=${this.entityId}`)
      .then((response) => {
        this.comments = response.data;
      })
      .catch((error) => {
        console.log(error.response.data)
      });
  }
}
</script>

<style scoped lang="scss">
@import "src/scss/mixins";

.comments {
  margin-top: 40px;
}
</style>
