<template>
  <div class="comments">
    <div class="row">
      <div class="col-md-12">
        <form class="form-inline" @submit="checkPhone">
          <div class="form-group mx-sm-3 mb-2">
            <label class="sr-only">Введите номер</label>
            <input type="text" v-mask="'+7 ### ### ## ##'" v-model="phone" class="form-control" placeholder="+7 ">
          </div>
          <button type="submit" class="btn btn-primary mb-2 btn-sm">Проверить</button>
        </form>
      </div>
    </div>
    <div class="row" v-if="isEmpty">
      <div class="col-md-12 empty">
        <p>Ничего не найдено</p>
      </div>
    </div>
    <div class="row mb-20" v-if="client">
      <div class="col-md-12 hint">
        <strong>Клиент:</strong> <a v-if="isAdmin" :href="'/client/properties?id=' + client.id + '&type=newCreate'" target="_blank">{{ client.firstName }} / {{ client.companyName }} / {{ getStatusName(client) }}</a> <span v-else >{{ client.firstName }} / {{ client.companyName }} / {{ getStatusName(client) }}</span>
        <br>
        <strong>Сотрудник:</strong> {{ client.owner.firstName }} {{ client.owner.lastName }}
      </div>
    </div>
    <div class="row" v-if="properties">
      <div class="col-md-12">
        <table class="table table-striped table-bordered">
          <tbody>
          <tr v-for="(item, i) in properties" :key="i">
            <td width="80px">{{ item.id }}</td>
            <td><a :href="'/property/view?id='+item.id" target="_blank">{{ item.name }}</a></td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: 'CheckEmail',
  components: {},
  props: ['isAdmin'],
  data: () => {
    return {
      phone: '',
      client: null,
      properties: [],
      isEmpty: false,
    }
  },
  methods: {
    getStatusName(client){
      switch (client.status) {
        case 'active':
          return 'Активный'
        case 'archive':
          return 'Архив'
        case 'passive':
          return 'Пассивный'
        case 'agent':
          return 'Агент'
      }
    },
    checkPhone(e) {
      //234 234 23 42
      axios.get(`/site/check-phone/?phone=${this.phone}`)
        .then((response) => {
          this.client = response.data.client;
          this.properties = response.data.properties;

          if (!this.client && !this.properties.length) {
            this.isEmpty = true;
          } else {
            this.isEmpty = false;
          }
        })
        .catch((error) => {
          console.log(error.response.data)
        });
      e.preventDefault();
    },
  },
}
</script>

<style scoped lang="scss">
@import "src/scss/mixins";

.form-inline {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.empty{
  text-align: center;
  font-weight: 600;
  font-size: 17px;
  color: #e91e63;
}
</style>
