import { render, staticRenderFns } from "./Commets.vue?vue&type=template&id=bbede734&scoped=true&"
import script from "./Commets.vue?vue&type=script&lang=js&"
export * from "./Commets.vue?vue&type=script&lang=js&"
import style0 from "./Commets.vue?vue&type=style&index=0&id=bbede734&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bbede734",
  null
  
)

export default component.exports