<template>
  <div class="notifications">
    <div v-on:click="toggle()" class="notifications__button">
      <i class="fa fa-bell" aria-hidden="true"></i> ({{ countNew }})
    </div>
    <div class="notifications__popup" v-if="isShow">
      <div class="notification" v-for="item in notifications" v-bind:key="item.id" :class="{ 'notification_new': !item.isRead }">
        <div class="notification__title">{{ item.title }}</div>
        <div class="notification__text" v-html="item.text"></div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: 'Notifications',
  components: {},
  props: [],
  data: () => {
    return {
      isShow: false,
      countNew: 0,
      notifications: []
    }
  },
  methods: {
    toggle() {
      this.isShow = !this.isShow;
    }
  },
  mounted() {
    setInterval(() => {
      axios.get(`/notifications/index`)
          .then((response) => {
            this.notifications = response.data;
            const countNew = this.notifications.filter((item) => item.isRead == false).length;
            if (this.countNew !== countNew) {
              this.isShow = true;
              this.countNew = countNew;
            }
          })
          .catch((error) => {
            console.log(error.response.data)
          });
    }, 4000)
  }
}
</script>

<style scoped lang="scss">
@import "src/scss/mixins";

.notifications {
  position: relative;

  &__button {
    cursor: pointer;
  }

  &__popup {
    overflow: auto;
    max-height: 200px;
    position: absolute;
    right: -100px;
    width: 400px;
    background: #fff;
    border-radius: 5px;
    top: 45px;
    -webkit-box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
    box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
  }
}

.notification {
  border-bottom: 1px solid #ddd;
  padding: 9px;

  &:last-child {
    border: none;
  }

  &__title {
    font-weight: bold;
  }

  &_new {
    background: #fdefc5;
  }
}
</style>
